<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Tabulator - Developer Documentation" />
    <!--EOC-->
    <v-row>
      <v-col cols="12" sm="3">
        <TheLeftSidebar :leftMenuItems="leftmenu"></TheLeftSidebar>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card min-height="70vh" rounded="lg">
          <v-card-title>Export/Download Data</v-card-title>
          <v-card-text>
            <h6 style="margin: 20px 0px 20px">Example Table with Download</h6>

            <v-btn style="margin-right: 5px;" color="primary" @click="downloadCsvData">
              Download Csv
            </v-btn>



            <v-btn style="margin-right: 5px;" color="primary" @click="downloadXlsxData">
              Download Xlsx
            </v-btn>

            <v-btn color="primary" @click="downloadPdfData">
              Download Pdf
            </v-btn>

            <div>
            <div id="pagination-counter" style="float: left; margin-right: 2px;"></div>
            <span id="record-count" style="float: left;font-size: 14px; margin-top: 2px;"></span>
            <div style="clear: both;"></div>
          </div>

            <BreadTabulator
              ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              modelKey="demoProduct"
              @updateEditedCellData="updateEditedCellData"
            ></BreadTabulator>

            <p>
              Tabulator allows you to download/export the table data as a file
              directly from your browser, no server needed.
            </p>

            <p>
              The download will contain the text values of all data currently
              visible in the table, matching the current column layout, column
              titles, sorting and filtering.
            </p>

            <p>You have a choice of four file types to choose from:</p>
            <ul>
              <li><strong>csv - </strong>Comma separated value file</li>
              <li><strong>json - </strong>JSON formatted text file</li>
              <li>
                <strong>xlsx - </strong>Excel File (Requires the
                <a href="https://sheetjs.com/" target="_blank"
                  >SheetJS Library</a
                >)
              </li>
              <li>
                <strong>pdf - </strong>PDF File (Requires the
                <a href="https://github.com/parallax/jsPDF" target="_blank"
                  >jsPDF Library</a
                >
                and
                <a
                  href="https://github.com/simonbengtsson/jsPDF-AutoTable"
                  target="_blank"
                  >jsPDF-AutoTable Plugin)</a
                >
              </li>
              <li><strong>html - </strong>HTML Table file</li>
            </ul>

            <p>
              To trigger a download, call the
              <strong>download</strong> function, passing the file type (from
              the above list) as the first argument, and an optional second
              argument of the file name for the download (if this is left out it
              will be "Tabulator.ext"). The optional third argument is an object
              containing any setup options for the formatter, such as the
              delimiter choice for CSV's
            </p>

            <p>
              An optional third argument can be used to pass an options object
              to a downloader. Each downloader had a specific set of options
              availablefor this argument
            </p>

            <CopyToClipBoard :text-to-copy="dataDownloadCode"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ dataDownloadCode }}</pre>
            </VueCodeHighlight>

            <h6>Download Contents</h6>

            <p>
              The download table will contain column header groups, row groups,
              data trees and column calculations.
            </p>

            <p>
              You can choose to remove any of these from the output data by
              setting the values in the <strong>downloadConfig</strong> option
              in the table definition:
            </p>

            <CopyToClipBoard :text-to-copy="downloadContents"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ downloadContents }}</pre>
            </VueCodeHighlight>

            <h6>Row Range</h6>
            <p>
              By default, only the active rows (rows that have passed filtering)
              will be included in the download the
              <strong>downloadRowRange</strong> option takes a Row Range Lookup
              value and allows you to choose which rows are included in the
              download output:
            </p>

            <ul>
              <li>
                <strong>active</strong> - Rows currently in the table (rows that
                pass current filters etc - default)
              </li>
              <li>
                <strong>visible</strong> - Rows currently visible in the table
                viewport
              </li>
              <li>
                <strong>selected</strong> - Rows currently selected by the
                selection module (this includes not currently active rows)
              </li>
              <li>
                <strong>all</strong> - All rows in the table regardless of
                filters
              </li>
            </ul>

            <CopyToClipBoard :text-to-copy="rowRange"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ rowRange }}</pre>
            </VueCodeHighlight>

            <p>
              You can override the <b>downloadRowRange</b> option when
              downloading a file but passing the Row Range Lookup value into the
              optional fourth argument of the <b>download</b> function:
            </p>

            <CopyToClipBoard :text-to-copy="overRideDownload"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ overRideDownload }}</pre>
            </VueCodeHighlight>

            <h6>CSV Download</h6>
            <p>
              By default CSV files are created using a comma (,) delimiter. If
              you need to change this for any reason the you can pass the
              options object with a <b>delimiter</b> property to the
              <b>download</b> function which will then use this delimiter
              instead of the comma.
            </p>

            <CopyToClipBoard :text-to-copy="csvDownload"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ csvDownload }}</pre>
            </VueCodeHighlight>

            <h6>XLSX Download</h6>

            <p>
              <b>Note : Dependency Required</b><br />
              The XLSX downloader requires that the SheetJS Library be included
              on your site, this can be included with the following script tag
            </p>

            <CopyToClipBoard :text-to-copy="xlsxDependency"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ xlsxDependency }}</pre>
            </VueCodeHighlight>

            <p>
              For more details -
              <a
                href="https://tabulator.info/docs/5.5/download#xlsx"
                target="_blank"
                >Click Here</a
              >
            </p>

            <h6>PDF Download</h6>
            <p>
              Dependency Required The PDF downloader requires that the jsPDF
              Library and jsPDF-AutoTable Plugin be included on your site, this
              can be included with the following script tags
            </p>

            <CopyToClipBoard :text-to-copy="pdfDependency"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ pdfDependency }}</pre>
            </VueCodeHighlight>

            <p>
              For more details -
              <a
                href="https://tabulator.info/docs/5.5/download#pdf"
                target="_blank"
                >Click Here</a
              >
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
        
        <script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
import TheLeftSidebar from "../components/PageDeveloperTabulator/TheLeftSidebar.vue";
import leftmenu from "../config/leftmenu.js";
import Service from "@/objects/service";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-okaidia.css";
//import DeveloperRouter from "../../developer/router/index.js";
/* import "vue-code-highlight/themes/window.css"; */
//BOC:[model]
import Model from "@/objects/model";
//EOC

//BOC:[api]
import Api from "@/objects/api";
//EOC

import { mapState } from "vuex";
export default {
  components: {
    TheLeftSidebar,
    CopyToClipBoard,
    VueCodeHighlight,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    leftmenu: leftmenu,

    //BOC:[api]
    api: new Api(),
    //EOC

    //BOC:[service]
    service: new Service(),
    //EOC

    //BOC:[model]
    model: new Model(),
    //EOC

    filters: [],

    conditions: [],

    dataDownloadCode: `this.$refs.tabulatorDataTable.tabulator.download("xlsx", "data.xlsx", {}); //download table data as a CSV formatted file with a file name of data.csv`,
    paginationSizeSelector: [10, 20, 30, 40, 50,100,500,1000,2000,5000,10000],
    paginationSize: 10,
    columns: [],
    emitDataToVue: `this.$emit("updateEditedCellData", cell);`,
    clickEvent: `@updateEditedCellData="updateEditedCellData"`,
    downloadContents: `var table = new Tabulator("#example-table", {
            downloadConfig:{
                columnHeaders:false, //do not include column headers in downloaded table
                columnGroups:false, //do not include column groups in column headers for downloaded table
                rowGroups:false, //do not include row groups in downloaded table
                columnCalcs:false, //do not include column calcs in downloaded table
                dataTree:false, //do not include data tree in downloaded table
            },
        });`,

    rowRange: `var table = new Tabulator("#example-table", {
         downloadRowRange:"selected", //change default selector to selected
        });`,
    overRideDownload: `this.$refs.tabulatorDataTable.tabulator.download("csv", "data.csv", {}, "visible"); //include only rows visible in the table viewport in the download output`,
    csvDownload: `this.$refs.tabulatorDataTable.tabulator.download("csv", "data.csv", {delimiter:"."}); //download a CSV file that uses a fullstop (.) delimiter`,
    xlsxDependency: `<INCLUDE script TAG src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.6/xlsx.full.min.js"></INCLUDE script TAG>`,
    pdfDependency:
      '<INCLUDE script TAG src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"></INCLUDE script TAG><INCLUDE script TAG src="https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.5.20/jspdf.plugin.autotable.min.js"></INCLUDE script TAG>',
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Export",
      to: { name: "" },
      exact: true,
    });
    //EOC

    //BOC:[model]
    this.model.getByKey("demoProduct");
    //EOC
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.columns.push({
      title: "Actions",
      filterable: false,
      headerSort: false,
      editor: "list",
      editorParams: {
        values: [
          {
            label: "View",
            value: "view",
          },
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      formatter: () => {
        return `<span class="">Action</span>`;
      },

      cellEdited: (cell) => {
        var cellValue = cell.getValue();
        switch (cellValue) {
          case "view":
            this.$router.push({
              name: "PageDeveloperComponentBreadTabulatorColumnsView",
              params: {
                columnKey: cell.getRow().getData().id,
              },
            });
            break;
          case "edit":
            alert("Editing row data");

            break;
          case "delete":
            alert("Deleting row data");
            break;
          default:
            break;
        }
      },
    });

    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },
  mounted() {
    //
  },
  methods: {
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/list`;
    },

    updateEditedCellData(cell) {
      let editedRecordId = cell.getRow().getData().id;
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();

      if (editedRecordField !== undefined) {
        var responseData = {
          [editedRecordField]: editedRecordValue,
        };

        this.api.setMethod("POST");
        this.api.setParams({
          data: JSON.stringify(responseData),
        });
        this.api.setUrl(
          `${this.$service[this.service.key]}/v1/en/console/model/${
            this.model.key
          }/${editedRecordId}/update`
        );

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        });

        this.api.fetch();
      }
    },

    downloadCsvData() {
      this.$refs.tabulatorDataTable.tabulator.download(
        "csv",
        `${this.model.key}.csv`,
        {}
      );
    },

    downloadXlsxData() {
      this.$refs.tabulatorDataTable.tabulator.download(
        "xlsx",
        `${this.model.key}.xlsx`,
        {
          sheetName: `${this.model.key}`,
          //documentProcessing:function(workbook){ /* do something */ },
        }
      ); //download a xlsx file using SheetJS properties
    },

    downloadPdfData() {

      this.$refs.tabulatorDataTable.tabulator.download(
        "pdf", 
        `${this.model.key}.pdf`, 
        {
        orientation: "portrait", //set page orientation to portrait
        title: "Demo Product Details", //add title to report
        autoTable: {
          //advanced table styling
          /* styles: {
            fillColor: [100, 255, 255], 
          },
          columnStyles: {
            id: { fillColor: 255 }, 
          },
          margin: { top: 60 }, */
        },
      });
    },
  },
};
</script>
      
      <style scoped>
</style>